<template>
	<div class="ds-container">
		<section class="ds-header">
			<div class="ds-title">
				<h2>Lotes finalizados</h2>
				<span id="help-icon" class="icon-box">
					<HelpCircle class="icon stroke" />
				</span>
				<b-tooltip target="help-icon" placement="bottom">
					Visualizar lotes finalizados.
				</b-tooltip>
			</div>
			<!-- <div class="btn-title-container">
				<b-button variant="outline-primary no-print" @click="print">Imprimir</b-button>
			</div> -->
		</section>
		<section>
			<SentLotsFilter
        :statusOptions="statusOptions"
        @change="onChangeFilter"
        :showStatus="false"
        class="no-print"
      />
		</section>
		<section v-if="batches.length">
			<FinishLotsContainer
				v-for="batch in batches"
				:key="batch.id"
        :batch="batch"
				:title="batch.title"
				:subtitles="batch.subtitles"
				:cards="batch.cards"
				:getGuide="getGuide"
				:openHistoric="openHistoric"
				:openDocumentModal="openDocumentModal"
        @updateGuides="handleGuideRows"
        @openDocumentListModal="openDocumentListModal"
				@onUpdate="getBatches()"
        @openInfo="openInfoSidebar"
        @onManageGuides="openManageGuides"
        @finishBatch="finishBatch"
			/>
			<div class="pagination-position">
				<b-pagination
          size="sm"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
        />
      </div>
		</section>
    <div v-else>
      <NoFiltersResults message="Ainda não há lotes disponíveis." />
    </div>
		<TissGuideAuditModal :tissGuideAudits="tissGuideAudits" />

		<TissGuidesModalsContainer
      :patient="patient"
      :tissGuide="tissGuide"
      :clinicHealthPlan="clinicHealthPlan"
      :appointmentId="tissGuide.appointment_id"
      :getLastTissGuide="getLastTissGuide"
      :clearTissGuide="clearTissGuide"
      :required_fields="tissRequiredCustomField"
    	readonly
      :setTissGuide="setTissGuide"
      :getGuide="getGuide"
    />

    <TissGuideFilesContainer
      :file="file"
      :tissGuide="tissGuide"
      :tissGuides="guidesRows"
      :openDocumentModal="openDocumentModal"
      :openEditFileModal="openEditFileModal"
      :clearFile="clearFile"
      @hide-file-list-modal="hideFileListModal"
      :files="files"
    />

    <GuidesInfoSidebar
      :batchId="selectedBatchId"
      @updateGuide="getBatches"
    />

    <BillsToReceiveTissModal 
      :billToReceive="billToReceive" 
      @reload="getBatches"
      @hide="billToReceive = null"
    />

	</div>
</template>

<script>
import api  from '@/modules/sus/manageGuides/api'
import { TissBatch }  from '@/modules/sus/manageGuides/utils/statuses'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { handleGuideUpdate } from '@/utils/invoicingSusHelper'

export default {
  props: {
	  print: Function
  },
	components: {
		HelpCircle: () => import('@/assets/icons/help-circle.svg'),
		FinishLotsContainer: () => import('@/modules/sus/manageGuides/layouts/FinishLotsContainer'),
    NoFiltersResults: () => import('@/components/General/noFilterResults'),
		SentLotsFilter: () => import('@/components/Sus/Generic/Filters/SentLotsFilter.vue'),
		TissGuideAuditModal: () => import('@/components/Sus/Modal/TissGuideAuditModal.vue'),
    TissGuideFilesContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuideFilesContainer.vue'),
		TissGuidesModalsContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue'),
    GuidesInfoSidebar: () => import('@/modules/sus/manageGuides/sidebar/GuidesInfoSidebar.vue'),
    BillsToReceiveTissModal: () => import('@/modules/financial/modals/BillsToReceiveTissModal'),
	},
	data() {
		return {
			clinic: getCurrentClinic(),
      statusOptions: [
        TissBatch.BATCH_STATUS_FINISHED
      ],
      batches: [],
      currentPage: 1,
      perPage: 10,
      selectedBatchId: null,
      billToReceive: null,

      totalItems: 0,
			filters: {},
      
			batchSelected: [],
			tissGuideAudits: [],
			file: {},
      files: [],
      patient: {},
			tissGuide: {},
			clinicHealthPlan: {},
      guidesRows: [],
      tissRequiredCustomField: {}
		}
	},
  async mounted() {
    await this.getBatches()
  },
	methods: {
    handleGuideRows(value){
      this.guidesRows = this.guidesRows.concat(value)
    },
		async onChangeFilter(newFilter) {
      this.filters = newFilter
      await this.getBatches()
    },
		async getBatches() {
      const isLoading = this.$loading.show()

      const filters = { ...this.filters }
      if (!filters?.status?.length) {
        filters.status = this.statusOptions
      }
      filters.type = filters.type?.value
    
      try {
				this.batches = []
        this.tableRows = []
				this.batchSelected = []

        const { data } = await api.getTissBatches(
          this.clinic.id,
          this.currentPage,
					filters
        )
       
        this.batches = data.data
        this.currentPage = data.current_page
        this.perPage = data.per_page
        this.totalItems = data.total
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async finishBatch(batch) {
      try {
        const { data } = await api.getBatchBillData(batch.id)

        if(data.amount){
          data.amount = 0;
        }

        this.billToReceive = data
        this.$bvModal.show('bills-to-receive-tiss-modal')
      } catch (err) {
        this.$toast.warning(err.message)
      } finally {

      }      
    },
		openCreateModal() {
			this.$bvModal.show('create-invoice-modal')
		},
		parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    async openManageGuides(batchInfo) {
      if (!batchInfo) return

      console.log('batchInfo ', batchInfo);

      if (!this.reloadContent) {
        await this.$router.push({
          name: 'tiss-manage-guides',
          params: { batchId: batchInfo.batch.id }
        })
      }
    },
    openInfoSidebar(id) {
      this.selectedBatchId = id
      this.$root.$emit('bv::toggle::collapse', 'guides-info-sidebar')
    },
		async getGuide(tissGuide, notOpen) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideById(tissGuide.id)
        this.tissGuide = response.data
        if (notOpen) return

        this.patient = this.tissGuide.patient
        await this.getClinicHealthPlan(
          this.tissGuide.invoicing_tiss_setting_id
        )
        this.handleGuide(this.tissGuide.guide_type)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
		async getLastTissGuide(id, guideId) {
      await this.getGuide(guideId, true)
    },
    async getClinicHealthPlan(invoicingTissSettingId) {
      if (!this.clinic.id || !invoicingTissSettingId) return
      try {
        const response = await this.api.findByClinicIdAndInvoicingTissSettingId(
          this.clinic.id,
          invoicingTissSettingId
        )
        this.clinicHealthPlan = response.data
        this.tissRequiredCustomField =
          this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    openDocumentModal(tissGuide) {
      this.tissGuide = tissGuide
      this.$bvModal.show('tiss-guide-file-modal')
    },
    formatDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    async getTissGuideFiles(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideFiles(tissGuideId)
        return response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openHistoric(tissGuideId) {
      await this.getTissGuideAudits(tissGuideId)
      this.$bvModal.show('tiss-guide-audit-modal')
    },
    async getTissGuideAudits(tissGuideId) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideAudits(tissGuideId)
        this.tissGuideAudits = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async openDocumentListModal(tissGuide) {
      this.tissGuide = tissGuide
      this.files = await this.getTissGuideFiles(tissGuide.id)
      this.$bvModal.show('tiss-guide-file-list-modal')
    },
    openEditFileModal(file) {
      this.file = file
      this.$bvModal.show('tiss-guide-file-modal')
    },
    openModalUpdateTiss({ tissGuide, type }) {
      this.tissGuide = tissGuide
      this.guide_type = type
      this.handleGuide()
    },
    clearFile() {
      this.file = {}
    },
    clearTissGuide() {
      this.tissGuide = {}
    },
    hideFileListModal(files) {
      if (files.length) {
        this.$set(this.tissGuide, 'files_count', files.length)
      } else {
        this.$set(this.tissGuide, 'files_count', 0)
      }
    },
    handleGuide(type) {
      const modal = handleGuideUpdate(type)
      this.$bvModal.show(modal)
    },
    setTissGuide(tissGuide){
      this.tissGuide = null
      this.tissGuide = tissGuide
    }
	},
  watch: {
    currentPage: {
      handler: async function () {
        await this.getBatches()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ds-container {
	padding: 30px 10px;

	.ds-header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0 0 0;
	}

	.ds-title {
		h2 {
			display: inline-flex;
			align-items: center;
			font-family: 'Red Hat Display';
			font-weight: 500;
			font-size: 18px;
		}

		.icon {
			height: 24px;
			width: 24px;
			margin-left: 5px;
			stroke: var(--neutral-500);
		}
	}

	.btn-title-container {
		button {
			margin-left: 15px;
		}
	}
}
</style>
